import {HTTP} from '../utils/axios'

class SoftlistsModel extends HTTP {
  /*首页渲染*/
  index(key = '', first_cate = '', second_cate = '', recommend = '', is_free = '', point = '', id = null, page = 1, all = '') {
      return this.request({
          url: 'board/apps',
          method: "GET",
          data: {
            key: key,
            first_cate_id: first_cate,
            second_cate_id: second_cate,
            is_recommend: recommend,
            is_free: is_free,
            point: point,
            page: page,
            id: id,
            all: all
          }
      })
  }

  /*增加*/
  create(value) {
    return this.request({
        url: "board/apps",
        method: "POST",
        data: value,
    })
  }

  /*修改*/
  update(value, id) {
    return this.request({
        url: "board/apps/" + id,
        method: "PATCH",
        data: value,
    })
  }

  /*删除*/
  destroy(id) {
    return this.request({
        url: 'board/apps/' + id,
        method: "DELETE"
    })
  }

  // 获取积分图表数据
  getPointChartData(limitCount) {
    return this.request({
        url: 'board/apps/app_point_data',
        method: "GET",
        data: {
          limit_count: limitCount
        }
    })
  }

    // 更新排序字段
    updateSequence(value, id) {
        return this.request({
            url: `board/apps/${id}/sequence`,
            method: "PATCH",
            data: value,
        })
    }
}

export {SoftlistsModel}