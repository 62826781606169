import {HTTP} from '../utils/axios'

class CategoryModel extends HTTP {
  /*首页渲染*/
  index(key = '',page = 1, parentId = null, all = '') {
      let data = {}
      data.key = key
      data.page = page
      data.all = all
      if (parentId) {
        data.parent_id = parentId
      }
      return this.request({
          url: 'board/categories',
          method: "GET",
          data: data
      })
  }

  /*增加*/
  create(value) {
    return this.request({
        url: "board/categories",
        method: "POST",
        data: value,
    })
  }

  /*修改*/
  update(value, id) {
    return this.request({
        url: "board/categories/" + id,
        method: "PATCH",
        data: value,
    })
  }

  /*删除*/
  destroy( id) {
    return this.request({
        url: 'board/categories/' + id,
        method: "DELETE",
    })
  }

  /*置顶*/
  set_top(value) {
    return this.request({
        url: 'board/categories/set_top',
        method: "PUT",
        data: value
    })
  }

  /*后移*/
  move_next(value) {
    return this.request({
        url: 'board/categories/move_next',
        method: "PUT",
        data: value
    })
  }
}

export {CategoryModel}