<template>
  <div>
    <a-form layout="inline" :form="form1" @submit="handleSearch">
      <a-form-item>
        <a-input v-decorator="[
          'key'
        ]" placeholder="请输入二级分类名称"></a-input>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit">搜索</a-button>
      </a-form-item>
      <a-form-item>
        <a-button type="danger" @click="handleClear" html-type="button">清空</a-button>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="showModal('new')">新增二级分类</a-button>
      </a-form-item>
    </a-form>
    <a-table :columns="columns" :dataSource="data" :pagination="false" :loading="loading">
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="handleById(record, 'update')">编辑</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="handleSetTop(record)" class="green">置顶</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="handleMoveNext(record)" class="green">后移</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="handleDelete2(record)" class="delete">删除</a>
      </span>
    </a-table>
    <Pagination :current="current" :total="total" @handleChange="handleChange" v-show="total > 20" />
    <a-modal
      :title="title"
      v-model="visible"
      @ok="handleOk"
      @cancel="handleCancel"
      okText="确认"
      cancelText="取消"
      width="450px"
    >
      <a-form :form="form2" :label-col="{ span: 6}" :wrapper-col="{ span: 18 }">
        <a-form-item label="分类名称">
          <a-input v-decorator="['name', { rules: [{ required: true, message: '请输入二级分类名称!' }] }]" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import myMixin from "./mixin";
const columns = [{
    title: "二级分类ID",
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '名称',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: {
      customRender: 'name'
    },
  },
  {
    title: '创建时间',
    dataIndex: 'created_at',
    key: 'created_at',
    scopedSlots: {
      customRender: "created_at"
    }
  },
  {
    title: '操作',
    dataIndex: '',
    key: 'action',
    scopedSlots: {
      customRender: 'action'
    }
  }
];
export default {
  mixins: [myMixin],
  data() {
    return {
      columns
    };
  }
};
</script>

<style lang="scss" scoped>
.ant-breadcrumb .ant-breadcrumb-link {
  font-size: 18px;
}
</style>
