import {
  CategoryModel
} from '../../../models/category'

import { 
  SoftlistsModel
} from "../../../models/softlists";
import Pagination from '../../../components/Pagination'
import formatTime from '../../../utils/formatTime'
import {
  mapMutations
} from 'vuex'
const categoryModel = new CategoryModel()
const softlistsModel = new SoftlistsModel()
var myMixin = {
  data() {
    return {
      form1: this.$form.createForm(this, {
        name: 'horizontal_login'
      }),
      data: [],
      visible: false,
      formLayout: 'horizontal',
      form2: this.$form.createForm(this, {
        name: 'coordinated'
      }),
      flag: '',
      editId: 0,
      loading: false,
      total: 0,
      title: '',
      current: 1
    };
  },

  created() {
    this.parentId = this.$route.params.id
    if (this.parentId) {
      this.queryCateList('', 1, this.parentId, '')
    } else {
      this.queryCateList()
    }
  },

  methods: {

    handleById(record, flag) {
      this.flag = flag
      this.editId = record.id
      this.visible = true;
      this.title = this.parentId ? "编辑二级分类" : "编辑一级分类"
      this.$nextTick(() => {
        this.form2.setFieldsValue({
          name: record.name,
        })
      })
    },

    handleDelete1(record) {
      categoryModel.index('', 1, record.id).then(res => {
        if (res.result.length > 0) {
          this.$confirm({
            content: '当前条目下存在二级分类，无法进行删除!',
            okText: '确定',
            cancelText: '取消',
            onOk: () => {},
            onCancel() {},
          });
        } else {
          this.$confirm({
            content: '确定要删除吗？',
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
              categoryModel.destroy(record.id).then((res) => {
                if (res.code == 0) {
                  this.$notification.open({
                    message: '提示',
                    description: res.message,
                    duration: 2
                  });
                  this.queryCateList()
                }
              })
            },
            onCancel() {},
          });
        }
      })
    },

    handleDelete2(record) {
      softlistsModel.index('', '', record.id, '',"", "", null, 1, '').then(res => {
        if (res.result.length > 0) {
          this.$confirm({
            content: '当前条目下存在软件，无法进行删除!',
            okText: '确定',
            cancelText: '取消',
            onOk: () => {},
            onCancel() {},
          });
        } else {
          this.$confirm({
            content: '确定要删除吗？',
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
              categoryModel.destroy(record.id).then((res) => {
                if (res.code == 0) {
                  this.$notification.open({
                    message: '提示',
                    description: res.message,
                    duration: 2
                  });
                  this.queryCateList('', 1, this.parentId, '')
                }
              })
            },
            onCancel() {},
          });
        }
      })
    },

    handleSetTop(record) {
      this.$confirm({
        content: '确定要置顶吗？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          categoryModel.set_top({
            id: record.id
          }).then((res) => {
            if (res.code == 0) {
              this.$notification.open({
                message: '提示',
                description: res.message,
                duration: 2
              });
              if (this.parentId) {
                this.queryCateList('', 1, this.parentId, '')
              } else {
                this.queryCateList()
              }
            }
          })
        },
        onCancel() {},
      });
    },

    handleMoveNext(record) {
      this.$confirm({
        content: '确定要后移吗？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          categoryModel.move_next({
            id: record.id
          }).then((res) => {
            if (res.code == 0) {
              this.$notification.open({
                message: '提示',
                description: res.message,
                duration: 2
              });
              if (this.parentId) {
                this.queryCateList('', 1, this.parentId, '')
              } else {
                this.queryCateList()
              }
            }
          })
        },
        onCancel() {},
      });
    },

    showModal(flag) {
      this.flag = flag
      this.visible = true;
      this.title = this.parentId ? "新增二级分类" : "新增一级分类"
    },

    handleChange(page) {
      this.current = page
      this.form1.validateFields((err, values) => {
        if (!err) {
          if (this.parentId) {
            this.queryCateList(values.key, page, this.parentId, '')
          } else {
            this.queryCateList(values.key, page)
          }
        }
      });
    },

    handleOk(e) {
      e.preventDefault();
      this.form2.validateFields((err, values) => {
        if (!err) {
          values.parent_id = this.parentId ? this.parentId : null
          if (this.flag == 'new') {
            categoryModel.create(values).then((res) => {
              if (res.code == 0) {
                this.visible = false;
                this.$notification.open({
                  message: '提示',
                  description: res.message,
                  duration: 2
                });
                if (this.parentId) {
                  this.queryCateList('', 1, this.parentId, '')
                } else {
                  this.queryCateList()
                }
              }
            })
          } else {
            categoryModel.update(values, this.editId).then((res) => {
              if (res.code == 0) {
                this.visible = false;
                this.$notification.open({
                  message: '提示',
                  description: res.message,
                  duration: 2
                });
                if (this.parentId) {
                  this.queryCateList('', 1, this.parentId, '')
                } else {
                  this.queryCateList()
                }
              }
            })
          }
          this.form2.resetFields();
        }
      });
    },

    handleCancel() {
      this.form2.resetFields();
    },

    handleSearch(e) {
      e.preventDefault();
      this.form1.validateFields((err, values) => {
        if (!err) {
          let key = values.key ? values.key.trim() : ''
          if (this.parentId) {
            this.queryCateList(key, 1, this.parentId, '')
          } else {
            this.queryCateList(key, 1)
          }
        }
      });
    },

    handleClear() {
      if (this.parentId) {
        this.queryCateList('', 1, this.parentId, '')
      } else {
        this.queryCateList()
      }
      this.form1.resetFields();
    },

    getDetail(record) {
      this.$router.push({
        path: `/board/categories/${record.id}`,
        query: {
          name: `${record.name}`
        }
      })
      this.parentName(record.name)
    },

    queryCateList(key, page, parentId, all) {
      this.loading = true
      categoryModel.index(key, page, parentId, all).then((res) => {
        if (res.code == 0) {
          this.current = page
          res.result.map((item, index) => {
            item.key = index
            item.created_at = formatTime(item.created_at)
            return item
          })
          this.data = res.result
          this.loading = false
          this.total = res.total
        }
      })
    },

    ...mapMutations({
      parentName: 'PARENT_NAME'
    })
  },

  components: {
    Pagination
  }
};

export default myMixin